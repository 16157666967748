import React from 'react'
import Error from 'next/error'

import DynamicPage, { getStaticProps as getDynamicPageProps } from '@pages/[...slug]'

const slug = ['__404-en__']

export async function getStaticProps() {
  const { props } = await getDynamicPageProps({ params: { slug } })

  return { props: props ?? {} }
}

export default function Page({ page, globalContext }) {
  if (!page) {
    return <Error statusCode={404} />
  }

  return <DynamicPage page={page} globalContext={globalContext} />
}
